@tailwind base;
@tailwind components;
@tailwind utilities;

html, body{
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

#root{
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

*{
	box-sizing: border-box;
}

*:not(input, textarea){
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}